import React, { FC } from 'react'
import styled from '@emotion/styled'
import SEO from '@/components/head/SEO'
import { rem } from '@/utils/helpers'
import Section from '@/components/layouts/Section'
import Container from '@/components/layouts/Container'
import Typography from '@/components/typography'
import Button from '@/components/snippets/Button'

interface Props {
  [key: string]: any
}

const NotFound: FC<Props> = () => (
  <Root>
    <SEO title="404 NOT FOUND" />
    <Section color="black.0">
      <Container type={3}>
        <Head>
          <Title variant="h3" component="h1" lang="en">
            404 NOT FOUND
          </Title>
        </Head>
        <Main>
          <Content>
            <p>お探しのページまたはコンテンツは見つかりませんでした。</p>
          </Content>
        </Main>
        <Foot>
          <Button to="/" color="primary" iconAfter="right">
            サイトトップへ
          </Button>
        </Foot>
      </Container>
    </Section>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Title = styled(Typography)`
  text-align: center;
`

const Main = styled.div`
  margin-top: ${rem(40)};
`

const Content = styled.div`
  p {
    text-align: center;
    font-size: ${rem(14)};
    line-height: 1.8;
  }
`

const Foot = styled.div`
  margin-top: ${rem(48)};
  display: flex;
  justify-content: center;
`

export default NotFound
